<template>
  <d-edit-card
    ref="editForm"
    prev-page="PayoutAssignment"
    :key="$route.params.id"
    :id="$route.params.id"
    :entity.sync="form"
    :fetch-function="ruleManagerController.findRuleById"
    :card-title="`Rule ${isEditingForm ? 'updating' : 'creation'}`"
    :submit-function="submitForm"
    :submit-success-title="
      isEditingForm
        ? 'Changes successfully saved!'
        : 'Rule successfully created!'
    "
    redirect-after-creation="PayoutAssignmentEdit"
    class="payout-assignment-rules"
  >
    <template #default="{ data, loading }">
      <q-card-section class="col">
        <div class="row">
          <div :style="{ maxWidth: '1024px' }">
            <d-select-filtered
              class="col-md-4 col-12"
              label="Payout method type"
              clearable
              :dense="$q.screen.lt.md"
              outlined
              emit-value
              map-options
              option-value="id"
              option-label="name"
              :options="payoutMethodsDictionary"
              :disable="loading || isEditingForm"
              :rules="[(val) => !!val || 'Value mast be selected!']"
              hint="Required field*"
              v-model="data.payoutMethodType.id"
            />
            <d-percentage-table
              v-model="data.rules"
              :is-percentage-sum-error="isPercentageSumError"
              :payout-method-type-id="form.payoutMethodType.id"
              class="payout-assignment-rules__table q-mt-lg"
            />
          </div>
        </div>
      </q-card-section>
    </template>
    <template #actions="{ loading }">
      <d-edit-form-actions
        :disable="loading || isDisabledSubmit"
        :is-editing-form="isEditingForm"
        prev-page="PayoutAssignment"
      />
    </template>
  </d-edit-card>
</template>

<script>
import { DPercentageTable } from './features';
import { DEditFormActions } from '@/features/edit-form-actions';
import { DSelectFiltered } from '@/features/select-filtered';
import DEditCard from '@/layouts/edit-card';
import { payoutMethodController, ruleManagerController } from '@/shared/api';
import withIsEditingForm from '@/shared/mixins/withIsEditingForm';
import { authUtils, notify } from '@/shared/utils';

export default {
  components: {
    DEditCard,
    DEditFormActions,
    DSelectFiltered,
    DPercentageTable,
  },
  mixins: [withIsEditingForm()],
  data: () => ({
    ruleManagerController: ruleManagerController(
      authUtils.getRoleByHierarchy()
    ),
    payoutMethodsDictionary: [],
    agentsDictionary: [],
    isPercentageSumError: false,
    form: {
      payoutMethodType: {
        id: null,
      },
      rules: [],
      status: 1,
    },
  }),
  async mounted() {
    try {
      this.$refs.editForm.setLoading(true);
      const { data } = await payoutMethodController(
        authUtils.getRoleByHierarchy()
      ).getPayoutMethods();

      this.payoutMethodsDictionary = data;
    } catch (e) {
      notify.error('Error loading directory');
    } finally {
      this.$refs.editForm.setLoading(false);
    }
  },
  computed: {
    isDisabledSubmit: ({ form }) =>
      !form.payoutMethodType.id && !form.rules.length,
  },
  methods: {
    async submitForm(payload) {
      const percentageSummary = this.form.rules.reduce(
        (acc, el) => acc + el.percent,
        0
      );

      if (percentageSummary !== 100) {
        this.isPercentageSumError = true;
        throw new Error('The total percentage should be 100%');
      } else {
        this.isPercentageSumError = false;
      }

      if (this.isEditingForm) {
        return this.ruleManagerController.updateRule(payload);
      } else {
        return this.ruleManagerController.createRule(payload);
      }
    },
  },
};
</script>

<style lang="scss" src="./index.scss" scoped></style>
