<template>
  <q-select
    v-bind="$attrs"
    ref="select"
    :value="value"
    :options="localOptions"
    use-input
    @filter="filterFn"
    @input="$emit('input', $event)"
  />
</template>

<script>
import withFormControlValidation from '@/shared/mixins/withFormControlValidation';

export default {
  mixins: [withFormControlValidation('select')],
  props: {
    value: {
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    localOptions: [],
  }),
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.localOptions = val;
      },
    },
  },
  methods: {
    filterFn: function (val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.localOptions = this.options.filter((v) => {
          return (
            v[this.$attrs['option-label'] || 'name']
              .toLowerCase()
              .indexOf(needle) > -1
          );
        });
      });
    },
  },
};
</script>
