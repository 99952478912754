var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-edit-card',{key:_vm.$route.params.id,ref:"editForm",staticClass:"payout-assignment-rules",attrs:{"prev-page":"PayoutAssignment","id":_vm.$route.params.id,"entity":_vm.form,"fetch-function":_vm.ruleManagerController.findRuleById,"card-title":("Rule " + (_vm.isEditingForm ? 'updating' : 'creation')),"submit-function":_vm.submitForm,"submit-success-title":_vm.isEditingForm
      ? 'Changes successfully saved!'
      : 'Rule successfully created!',"redirect-after-creation":"PayoutAssignmentEdit"},on:{"update:entity":function($event){_vm.form=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var data = ref.data;
      var loading = ref.loading;
return [_c('q-card-section',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('div',{style:({ maxWidth: '1024px' })},[_c('d-select-filtered',{staticClass:"col-md-4 col-12",attrs:{"label":"Payout method type","clearable":"","dense":_vm.$q.screen.lt.md,"outlined":"","emit-value":"","map-options":"","option-value":"id","option-label":"name","options":_vm.payoutMethodsDictionary,"disable":loading || _vm.isEditingForm,"rules":[function (val) { return !!val || 'Value mast be selected!'; }],"hint":"Required field*"},model:{value:(data.payoutMethodType.id),callback:function ($$v) {_vm.$set(data.payoutMethodType, "id", $$v)},expression:"data.payoutMethodType.id"}}),_c('d-percentage-table',{staticClass:"payout-assignment-rules__table q-mt-lg",attrs:{"is-percentage-sum-error":_vm.isPercentageSumError,"payout-method-type-id":_vm.form.payoutMethodType.id},model:{value:(data.rules),callback:function ($$v) {_vm.$set(data, "rules", $$v)},expression:"data.rules"}})],1)])])]}},{key:"actions",fn:function(ref){
      var loading = ref.loading;
return [_c('d-edit-form-actions',{attrs:{"disable":loading || _vm.isDisabledSubmit,"is-editing-form":_vm.isEditingForm,"prev-page":"PayoutAssignment"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }