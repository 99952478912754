export const percentageColumns = [
  {
    name: 'agent-id',
    field: 'agentId',
    label: 'Agent',
    align: 'left',
  },
  {
    name: 'percent',
    field: 'percent',
    label: 'Percent',
    align: 'left',
  },
  {
    name: 'actions',
    field: 'actions',
    align: 'center',
    label: '',
  },
];
